import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-support-reply",
	templateUrl: "./support-reply.component.html",
	styleUrl: "./support-reply.component.css",
})
export class SupportReplyComponent implements OnInit {
	replyContent: any;
	replies: any;
	newDate: any;
	allReplies: any[] = [];
	descriptionDate: any;
	constructor(
		public dialogRef: MatDialogRef<SupportReplyComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {
		// this.newDate = this.addTime(this.originalDate, 5, 30);
	}

	ngOnInit(): void {
		// this.descriptionDate = this.addTime(this.data.allReply.issueDate, 0, 0);
		this.descriptionDate = this.data.allReply.issueDate;
		this.allReplies = this.data.allReply.replies.map((el: any) => {
			return {
				...el,
				// replyDate: this.addTime(el.replyDate, 0, 0),
				replyDate: el.replyDate,
			};
		});
		// this.newDate = this.addTime(this.originalDate, 5, 30);
	}

	// addTime(dateString: string, hours: number, minutes: number): string {
	// 	const date = new Date(dateString);
	// 	// date.setHours(date.getHours() + hours);
	// 	// date.setMinutes(date.getMinutes() + minutes);
	// 	const transformedDate = this.datePipe.transform(date, "MM-dd-yyyy,HH:mm ");
	// 	return transformedDate ?? "Invalid date";
	// }

	close() {
		this.dialogRef.close();
	}

	submitReply() {
		if (!this.replyContent) {
			this.toaster.warning("Please Enter your Message");
			return;
		}
		let payload = {
			replyContent: this.replyContent,
			reportIssueId: this.data.tableRow.id,
		};
		this.dialogRef.close(payload);
	}
}

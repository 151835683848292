import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-show-details",
	templateUrl: "./show-details.component.html",
	styleUrl: "./show-details.component.css",
})
export class ShowDetailsComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<ShowDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster: ToasterService
	) {}

	ngOnInit(): void {
		console.log(this.data);
	}
	close() {
		this.dialogRef.close();
	}
}

<div class="icon">
  <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<h2 mat-dialog-title class="headerColor">{{data.userName}}</h2>
<mat-divider>
</mat-divider>
<mat-dialog-content class="mat-typography">
  <h6 class="title text-center">Issue Detail</h6>
  <p class="issuedetail">{{data.reason}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
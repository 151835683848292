<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<h2 mat-dialog-title class="headerColor">Comment</h2>

<mat-divider>
</mat-divider>
<h3 mat-dialog-title class="userName">{{data.tableRow.userName}}</h3>
<mat-dialog-content class="mat-typography">
    <div class="cardReply">
        <mat-card class="my-2">
            <mat-card-header class="pt-0">
                <div class="d-flex w-100 justify-content-between align-items-baseline">
                    <div class="title">Description:</div>
                    <div class="date"> {{descriptionDate | date:'MM-dd-yyyy,HH:mm'}}</div>
                </div>
            </mat-card-header>
            <mat-card-content class="content">{{data.allReply.description}}</mat-card-content>
        </mat-card>

        <mat-card class="my-2" *ngFor="let reply of allReplies">
            <mat-card-header class="pt-0">
                <div class="d-flex w-100 justify-content-between align-items-baseline">
                    <div class="title d-flex align-items-baseline">Admin Reply:</div>
                    <div class="date"> {{reply?.replyDate | date:'MM-dd-yyyy,HH:mm'}}</div>
                </div>
            </mat-card-header>
            <mat-card-content class="content">{{reply.reply}}</mat-card-content>
        </mat-card>
    </div>


    <mat-divider class="my-3">
    </mat-divider>
    <textarea class="form-control" placeholder="Type your message here...
" name="" id="" cols="30" rows="2" [(ngModel)]="replyContent"></textarea>
</mat-dialog-content>

<mat-dialog-actions align="center" class="mb-2">
    <button mat-raised-button mat-dialog-close class="cancel-button">Cancel</button>
    <button mat-flat-button class="submit" color="primary" cdkFocusInitial (click)="submitReply()">Reply</button>
</mat-dialog-actions>